import React from 'react';
import { graphql } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
    
    <section className="page-section products-features"
        id="jiralanding"
        name="jiralanding"
        >
        <Container>
          <h2
            className="text-center pt-5"
            style={{
              fontSize: 62,
              fontWeight: 'bold',
            }}
          >
            Import all your Jira Data in Tableau
          </h2>
          <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 20}}>
                <b>It's as simple as opening the connector link in your Tableau! {'    '} </b>
                <input
                  type="button"
                  onClick={() =>

                    navigator.clipboard.writeText('https://app.daexus.io/jira/')
                  }
                  value="Copy link"
                />
                </p>
              </Row>
          <div
            style={{
              backgroundColor: '#edca21',
              height: 2,
              width: 80,
              margin: '16px auto',
            }}
          >
          </div>
          <br />
          <p className="text-center text-dark pb-5" style={{ fontWeight: 300 }}>
          Use your already-existing Daexus credentials to log into the premium version, or{' '} 
          <a href="https://profile.daexus.io/signup/jira"
          target="_blank"
          style={{ textDecoration: 'underline' }}>
          sign-up here for a free trial.
          </a>
          <br />{' '}
          Follow the instructions in the articles below to access & use the new Jira connector, and always 
          reach out if you need help!

          </p>
          <Row>
            <Col xs={12} lg={6} className="pr-lg-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
                <h4>Accessing{' '}
                <a href="/pdf/HOW TO ACCESS THE JIRA CONNECTOR.pdf" 
                target="_blank" 
                style={{ textDecoration: 'underline' }}>
                the Jira Connector </a></h4>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Authorizing {''}
                <a href="/pdf/HOW TO AUTHORIZE YOUR JIRA CREDENTIALS.pdf" 
                target="_blank"
                style={{ textDecoration: 'underline' }}>
                JIRA credentials    
              </a></h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Submiting {''}
                <a href="/pdf/HOW TO SUBMIT A QUERY IN TABLEAU USING THE DAEXUS CATALYST FOR JIRA.pdf" 
                target="_blank"
                style={{ textDecoration: 'underline' }}>
                 a query in Tableau <br /></a>{''}using the Daexus Catalyst for Jira</h4>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Automating {''}
                <a href="/pdf/HOW TO AUTOMATE YOUR JIRA REPORTS USING TABLEAU SERVER.pdf" 
                target="_blank"
                style={{ textDecoration: 'underline' }}>
                  JIRA reports using Tableau Server</a></h4>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Automating {''}
              <a href="/pdf/HOW TO AUTOMATE YOUR JIRA REPORTS USING TABLEAU ONLINE.pdf" 
              target="_blank"
              style={{ textDecoration: 'underline' }}>
              JIRA reports using Tableau Online
                <br />
              </a></h4>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>
              <a href="/pdf/DATA SECURITY.pdf" 
              target="_blank"
              style={{ textDecoration: 'underline' }}>
                Data Security {''}
                <br />
              </a></h4>
            </Col>
          </Row>
        </Container>
      </section> 
      <section className="page-section bg-secondary text-primary">
        <Container className="my-5">
          <Row>
            <Col xs={12} lg className="text-center">
              <img src="/img/bubels@3x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>
                We’ve got your back
              </h2>
              <p style={{ color: '#304F61', fontWeight: 300 }}>
                Reach out to our dedicated support team if you need help; use
                the chat box at the bottom of the screen or send us an email at{' '}
                <a href="mailto:support@daexus.io">support@daexus.io</a>
                <br />
                <strong>We’ll try to get back to you soon as we can!</strong>
                <br />
                <br />
              <Button variant="outline-primary" href="https://profile.daexus.io/signup/jira">
                Try it out now&nbsp;&nbsp;
              </Button>
              </p>

            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query JiraLanding($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
